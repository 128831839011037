// extracted by mini-css-extract-plugin
export var xs = "375";
export var sm = "576";
export var md = "768";
export var lg = "992";
export var xl = "1200";
export var xxl = "1440";
export var fhd = "1921";
export var element = "CookiesBanner-module--element--3me98";
export var link = "CookiesBanner-module--link--2vKgj";
export var title = "CookiesBanner-module--title--19E3R";
export var info = "CookiesBanner-module--info--1mmuL";
export var wrapper = "CookiesBanner-module--wrapper---C-A0";
export var line = "CookiesBanner-module--line--1qSxY";
export var button = "CookiesBanner-module--button--gFRtO";