// extracted by mini-css-extract-plugin
export var xs = "375";
export var sm = "576";
export var md = "768";
export var lg = "992";
export var xl = "1200";
export var xxl = "1440";
export var fhd = "1921";
export var container = "Layout-module--container--2IpcU";
export var justworks = "Layout-module--justworks--3GXCj";
export var headerBackgroundColor = "Layout-module--headerBackgroundColor--3SlHD";
export var footer_padding = "Layout-module--footer_padding--2NqEE";