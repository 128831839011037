import React, { useEffect, useRef, useState } from "react";
import * as styles from "./Dropdown.module.scss";
import DropdownI from "./Dropdown.type";
import { cn } from "../../../utils";
import { Link } from "gatsby";
import instagram from "../../../assets/icons/instagram.svg";
import linkedin from "../../../assets/icons/linkedin.svg";
import email from "../../../assets/icons/email.svg";
import twitter from "../../../assets/icons/twitter.svg";

const Dropdown = ({
  title,
  links,
  url,
  className,
  footer,
  white,
  setModal,
}: DropdownI) => {
  const ref = useRef<HTMLLIElement>(null);
  const [isOpen, setIsOpen] = useState(false);

  const checkIfClickedOutside = (e: any) => {
    if (isOpen && ref.current && !ref.current.contains(e.target)) {
      setIsOpen(false);
    }
  };
  useEffect(() => {
    document.addEventListener("click", checkIfClickedOutside);
    return () => {
      document.removeEventListener("click", checkIfClickedOutside);
    };
  });
  return (
    <li
      ref={ref}
      className={cn([
        styles.menuItem,
        className ? className : "",
        footer ? styles.footer : "",
        isOpen ? styles.open : "",
      ])}
      onClick={() => setIsOpen(oldState => !oldState)}
      key={(footer ? "footer_" : "header_") + title}
    >
      {links ? (
        <span
          className={cn([
            styles.title,
            styles.span,
            styles["icon"],
            footer ? styles.footer : "",
            white ? styles.white : ""
          ])}
        >
          {title}
        </span>
      ) : (
        <Link className={cn([styles.title, white ? styles.white : ""])} to={url ? url : "#"}>
          {title}
        </Link>
      )}
      {links && <span className={styles.fa} />}
      {links && (
        <div
          className={cn([
            styles.submenu,
            isOpen ? styles.open : "",
            styles.smallMenu,
          ])}
        >
          {links.map((link, index) => (
            <>
              {link.label !== "LinkedIn" &&
              link.label !== "Twitter" &&
              link.label !== "Instagram" ? (
                <Link
                  key={(footer ? "footer_" : "header_") + index}
                  to={link.url ? link.url : "#"}
                  className={styles.link}
                  onClick={
                    link.label === "Subscribe by email"
                      ? e => {
                          e.preventDefault();
                          setModal(true);
                        }
                      : null
                  }
                >
                  {link.label === "Subscribe by email" ? (
                    <img className={styles.icon} src={email} alt={"email"} />
                  ) : null}
                  {link.label}
                </Link>
              ) : (
                <a
                  key={index}
                  href={link.url ? link.url : "#"}
                  className={styles.link}
                  target="_blank"
                >
                  {link.label === "LinkedIn" ? (
                    <img
                      className={styles.icon}
                      src={linkedin}
                      alt={"linkedin"}
                    />
                  ) : null}
                  {link.label === "Twitter" ? (
                    <img
                      className={styles.icon}
                      src={twitter}
                      alt={"twitter"}
                    />
                  ) : null}
                  {link.label === "Instagram" ? (
                    <img
                      className={styles.instagram}
                      src={instagram}
                      alt={"instagram"}
                    />
                  ) : null}
                  {link.label}
                </a>
              )}
            </>
          ))}
        </div>
      )}
    </li>
  );
};

export default Dropdown;
