import { Link } from "gatsby";
import { cn } from "../../utils";
import * as styles from "./Button.module.scss";
import ButtonI from "./ButtonI.type";

const Button = ({
  text,
  className,
  size = "medium",
  color = "blue",
  shape = "rounded",
  url,
  onClick,
  disabled,
  alignment = "center",
  contactButton = true,
}: ButtonI) => {
  return (
    <>
      <div className={alignment ? styles[alignment] : ""}>
      {url ? (
        <Link
          to={url}
          className={cn([
            styles.button,
            styles[shape],
            styles[size],
            styles[color],
            className ? styles[className] : "",
          ])}
        >
          {text}
        </Link>
        ) : contactButton ? ( 
            <Link
              to={"/contact/#contactForm"}
              className={cn([
                styles.button,
                styles[shape],
                styles[size],
                styles[color],
                className ? styles[className] : "",
              ])}
            >
              {text}
            </Link>
        ) : (
          <button
            disabled={disabled}
            onClick={onClick}
            className={cn([
              styles.button,
              styles[shape],
              styles[size],
              styles[color],
              className ? className : "",
            ])}
          >
            {text}
          </button>
        )}
      </div>
    </>
  );
};

export default Button;
