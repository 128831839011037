// extracted by mini-css-extract-plugin
export var xs = "375";
export var sm = "576";
export var md = "768";
export var lg = "992";
export var xl = "1200";
export var xxl = "1440";
export var fhd = "1921";
export var element = "Logo-module--element--2E9nd";
export var footerLogo = "Logo-module--footerLogo--26yy8";
export var img = "Logo-module--img--2BrQd";
export var white = "Logo-module--white--36t0V";