import React from "react";
import { cn } from "../../../utils";
import * as styles from "./MenuItems.module.scss";
import Dropdown from "../Dropdown/Dropdown";
import Button from "../../Button/Button";
import MenuItemsI from "./MenuItems.type";

const MenuItems = ({
  items,
  buttons = true,
  className,
  footer = false,
  setModal,
  menuCTALink,
  color,
  white
}: MenuItemsI) => {
  return (
    <div
      className={cn([
        styles.element,
        className ? className : "",
        footer ? styles["footer"] : "",
        white ? styles["white"] : "",
      ])}
    >
      <ul className={styles.list}>
        {items.map((item, index) => (
          <Dropdown
            key={(footer ? "footer_" : "header_") + index}
            title={item.label}
            url={item.url}
            links={item.links}
            footer={footer}
            hideFooter
            setModal={setModal}
            white={white}
          />
        ))}
      </ul>
      {buttons && (
        <div className={styles.buttons}>
          <Button
            className={cn([styles.buttonLogin, color === "white" ? styles["white"] : ""])}
            text="Log in"
            color="white"
            size="large"
            url={"https://client.via.work/login/"}
            contactButton={false}
          />
          {menuCTALink ? (
            <Button
              className={styles.button}
              text="Talk to us"
              color={color}
              size="large"
              url={menuCTALink}
              contactButton={false}
            />
          ) : (
            <Button
              className={styles.button}
              text="Talk to us"
              color={color}
              size="large"
              contactButton={true}
            />
          )}
        </div>
      )}
    </div>
  );
};

export default MenuItems;
