// extracted by mini-css-extract-plugin
export var xs = "375";
export var sm = "576";
export var md = "768";
export var lg = "992";
export var xl = "1200";
export var xxl = "1440";
export var fhd = "1921";
export var bannerContainer = "Banner-module--bannerContainer--2LSGi";
export var bannerText = "Banner-module--bannerText--2Lwyb";
export var bannerImgContainer = "Banner-module--bannerImgContainer--3jaCj";