// extracted by mini-css-extract-plugin
export var xs = "375";
export var sm = "576";
export var md = "768";
export var lg = "992";
export var xl = "1200";
export var xxl = "1440";
export var fhd = "1921";
export var header = "Header-module--header--1Qt4T";
export var titleImageContainer = "Header-module--titleImageContainer--3TKLm";
export var titleImageWrapper = "Header-module--titleImageWrapper--P70pt";
export var headerBackgroundColor = "Header-module--headerBackgroundColor--j_Iqa";
export var buttonLogin = "Header-module--buttonLogin--L0RNV";
export var background = "Header-module--background--21ZMD";
export var hasBanner = "Header-module--hasBanner---VFb-";
export var backgroundPadding = "Header-module--backgroundPadding--1FSqL";
export var backgroundGrey = "Header-module--backgroundGrey--227fu";
export var backgroundWhite = "Header-module--backgroundWhite--1aYFW";
export var element = "Header-module--element--a4cMT";
export var title = "Header-module--title--2NCtK";
export var button = "Header-module--button--3YOtC";
export var headerBlog = "Header-module--headerBlog--_GKUM";
export var headerCountryPage = "Header-module--headerCountryPage--MnDUW";
export var description = "Header-module--description--1i4lX";