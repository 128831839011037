// extracted by mini-css-extract-plugin
export var xs = "375";
export var sm = "576";
export var md = "768";
export var lg = "992";
export var xl = "1200";
export var xxl = "1440";
export var fhd = "1921";
export var nav = "Menu-module--nav--27MvI";
export var burger = "Menu-module--burger--ZvVuU";
export var burgerLines = "Menu-module--burgerLines--22FZg";
export var white = "Menu-module--white--3_GuH";
export var open = "Menu-module--open--2Xi8s";
export var menuItems = "Menu-module--menuItems--2hns6";