// extracted by mini-css-extract-plugin
export var xs = "375";
export var sm = "576";
export var md = "768";
export var lg = "992";
export var xl = "1200";
export var xxl = "1440";
export var fhd = "1921";
export var element = "FooterBottom-module--element--38pmD";
export var logo = "FooterBottom-module--logo--1CR3c";
export var links = "FooterBottom-module--links--3_R5F";
export var link = "FooterBottom-module--link--deTTW";
export var copyright = "FooterBottom-module--copyright--1-Eff";