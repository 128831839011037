// extracted by mini-css-extract-plugin
export var xs = "375";
export var sm = "576";
export var md = "768";
export var lg = "992";
export var xl = "1200";
export var xxl = "1440";
export var fhd = "1921";
export var element = "MenuItems-module--element--3ifEO";
export var footer = "MenuItems-module--footer--3nCTU";
export var buttons = "MenuItems-module--buttons--3m7Ac";
export var buttonLogin = "MenuItems-module--buttonLogin--1-jNM";
export var white = "MenuItems-module--white--2q3-4";
export var button = "MenuItems-module--button--253Cn";
export var list = "MenuItems-module--list--1P9Ku";