// extracted by mini-css-extract-plugin
export var xs = "375";
export var sm = "576";
export var md = "768";
export var lg = "992";
export var xl = "1200";
export var xxl = "1440";
export var fhd = "1921";
export var header = "HeaderTitle-module--header--DG2tJ";
export var title = "HeaderTitle-module--title--3-xO1";
export var section = "HeaderTitle-module--section--2tolD";
export var h1_title = "HeaderTitle-module--h1_title--13r2h";
export var dark = "HeaderTitle-module--dark--30MjH";
export var peach = "HeaderTitle-module--peach--82qJ1";
export var white = "HeaderTitle-module--white--3nVcA";
export var green = "HeaderTitle-module--green--Rde30";
export var blue = "HeaderTitle-module--blue--27PHT";
export var gold = "HeaderTitle-module--gold--DWOwe";
export var center = "HeaderTitle-module--center--1eoep";
export var titleImage = "HeaderTitle-module--titleImage--slP4s";
export var description = "HeaderTitle-module--description--19R_J";