// extracted by mini-css-extract-plugin
export var xs = "375";
export var sm = "576";
export var md = "768";
export var lg = "992";
export var xl = "1200";
export var xxl = "1440";
export var fhd = "1921";
export var center = "Button-module--center--3qPjW";
export var button = "Button-module--button--2t432";
export var left = "Button-module--left--2u_Ok";
export var right = "Button-module--right--2pAJo";
export var rounded = "Button-module--rounded--103fk";
export var small = "Button-module--small--1d711";
export var medium = "Button-module--medium--3Lmk0";
export var large = "Button-module--large--1KHrG";
export var square = "Button-module--square--1eOxK";
export var full = "Button-module--full--3ZM2X";
export var white = "Button-module--white--2Xn31";
export var dark = "Button-module--dark--15j6W";
export var blue = "Button-module--blue--2Tn0e";
export var peach = "Button-module--peach--R-rH6";
export var transparent = "Button-module--transparent--t6Qzt";
export var landingPage = "Button-module--landingPage--lWBIj";