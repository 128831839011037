import React from "react";
import FooterBottom from "./FooterBottom/FooterBottom";
import MenuItems from "../Menu/MenuItems/MenuItems";
import Logo from "../Logo/Logo";
import FooterI from "./Footer.type";

const Footer = ({ menu, logo, links, setModal, menuCTALink }: FooterI) => {
  return (
    <footer>
      <Logo logo={logo} footer={true} />
      <MenuItems
        items={menu}
        buttons={false}
        footer={true}
        setModal={setModal}
        menuCTALink={menuCTALink}
      />
      <FooterBottom logo={logo} links={links} />
    </footer>
  );
};

export default Footer;
