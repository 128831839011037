import React, { useState } from "react";
import { cn } from "../../utils";
import * as styles from "./Menu.module.scss";
import MenuItems from "./MenuItems/MenuItems";
import MenuTypeI from "./Menu.type";

const Menu = ({ items, setModal, menuCTALink, color, white }: MenuTypeI) => {
  const [open, setOpen] = useState(false);
  return (
    <nav className={styles.nav}>
      <div
        className={cn([styles.burger, open ? styles["open"] : ""])}
        onClick={() => setOpen(!open)}
      >
        <div className={cn([styles.burgerLines, white ? styles.white : ""])} />
      </div>
      <MenuItems
        items={items}
        setModal={setModal}
        className={cn([styles.menuItems, open ? styles["open"] : ""])}
        menuCTALink={menuCTALink}
        color={color}
        white={white}
      />
    </nav>
  );
};

export default Menu;
