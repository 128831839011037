import React from "react";
import * as styles from "./CookiesBanner.module.scss";
import CookiesBannerI from "./CookiesBanner.type";
import { cn } from "../../utils";
import { Link } from "gatsby";
import Button from "../Button/Button";
import { setCookie } from "../../utils/setCookie";
import { useLocation } from "@reach/router"; // this helps tracking the location
import { initializeAndTrack } from "gatsby-plugin-gdpr-cookies";

const CookiesBanner = ({
  cookieBarDescription,
  cookiePolicyLink,
  cookieBarTitle,
  dismiss,
}: CookiesBannerI) => {
  const location = useLocation();

  const setCookies = () => {
    setCookie("via-gdpr-google-tagmanager", true, 30);
    setCookie("gatsby-gdpr-tracking", true, 30);
    initializeAndTrack(location);
  };

  if (!cookieBarDescription || !cookiePolicyLink || !cookieBarTitle) {
    return null;
  }

  return (
    <div className={cn([styles.element])}>
      <p className={styles.title}>{cookieBarTitle}</p>
      <p className={styles.info}>{cookieBarDescription}</p>

      <div className={styles.line}>
        <div className={styles.wrapper}>
          <Button
            text="Accept"
            color={"white"}
            size={"small"}
            className={styles.button}
            onClick={() => {
              setCookies();
              dismiss();
            }}
            contactButton={false}
          />
          <Button
            text="Reject"
            color={"transparent"}
            size={"small"}
            className={styles.button}
            onClick={() => dismiss()}
            contactButton={false}
          />
        </div>
        <Link
          to={cookiePolicyLink.url ? cookiePolicyLink.url : "/cookie-policy/"}
          className={styles.link}
        >
          Learn more
        </Link>
      </div>
    </div>
  );
};

export default CookiesBanner;
