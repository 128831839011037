import React from "react";
import HeaderTitleItemI from "./HeaderTitle.type";
import * as styles from "./HeaderTitle.module.scss";
import { cn } from "../../utils";
import { GatsbyImage } from "gatsby-plugin-image";
import { isGatsbyImage } from "../../utils/helpers";

const HeaderTitle = ({
  type,
  h1_title,
  title,
  secondTitle,
  titleColor = "dark",
  classNameTitle,
  description,
  classNameText,
  secondTitleColor,
  center = false,
  secondLine = false,
  title_image,
}: HeaderTitleItemI) => {
  let hnTag = h1_title ? `h2` : `h1`;
  const Tag = type === "header" ? hnTag : `h3`;
  return (
    <>
      {h1_title && (
        <div className={styles.h1_title}>
          <h1>{h1_title}</h1>
        </div>
      )}
      {title_image &&
        (isGatsbyImage(title_image) ? (
          <GatsbyImage
            image={title_image.gatsbyImageData}
            alt={title_image.title}
            class={styles.titleImage}
          />
        ) : (
          <img
            src={title_image?.url}
            alt={title_image?.alternativeText}
            className={styles.titleImage}
          />
        ))}
      <Tag
        className={cn([
          styles.title,
          classNameTitle ? classNameTitle : "",
          styles[type],
          center ? styles.center : "",
        ])}
      >
        <span className={titleColor ? styles[titleColor] : ""}>{title} </span>
        {secondLine && <br />}
        {secondTitle && (
          <span className={secondTitleColor ? styles[secondTitleColor] : ""}>
            {secondTitle}
          </span>
        )}
      </Tag>
      {description?.description && (
        <p
          className={cn([
            styles.description,
            classNameText ? classNameText : "",
          ])}
          dangerouslySetInnerHTML={{
            __html: description.description.split("\n").join("<br/>"),
          }}
        />
      )}
    </>
  );
};

export default HeaderTitle;
