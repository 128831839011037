// extracted by mini-css-extract-plugin
export var xs = "375";
export var sm = "576";
export var md = "768";
export var lg = "992";
export var xl = "1200";
export var xxl = "1440";
export var fhd = "1921";
export var menuItem = "Dropdown-module--menuItem--1tr-W";
export var title = "Dropdown-module--title--WZ78A";
export var white = "Dropdown-module--white--U-eke";
export var smallMenu = "Dropdown-module--smallMenu--1mPCY";
export var bigSubMenu = "Dropdown-module--bigSubMenu--2NFFZ";
export var submenu = "Dropdown-module--submenu--2ftl6";
export var link = "Dropdown-module--link--1BRiq";
export var span = "Dropdown-module--span--8fisI";
export var fa = "Dropdown-module--fa--b8eVU";
export var open = "Dropdown-module--open--fzOl4";
export var footer = "Dropdown-module--footer--2KKQX";
export var icon = "Dropdown-module--icon--SVxji";
export var instagram = "Dropdown-module--instagram--1B344";