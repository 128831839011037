import React from 'react';
import * as styles from './JustworksHeader.module.scss';

const JustworksHeader: React.FC = () => {
    return (
        <header>
            <a href="https://via.work">
            <div className={styles.logo}>
                <svg width="371" height="72" viewBox="0 0 371 72" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M229.431 48.8109H1.24707V58.3039H229.431V48.8109Z" fill="black"/>
                    <path d="M0 39.2427L8.0413 37.7359C8.19398 41.8043 9.49178 43.7632 12.2146 43.7632C14.5812 43.7632 15.6754 42.6833 15.6754 39.5441V16.138H24.0475V37.5852C24.0475 45.9732 20.5358 49.4389 12.0365 49.4389C3.23179 49.4389 0.661626 44.3659 0 39.2427Z" fill="black"/>
                    <path d="M29.2388 40.8248V25.0785H37.4073V40.9755C37.4073 43.2609 38.5015 44.165 40.2828 44.165C42.6494 44.165 44.0744 42.7335 44.0744 39.4184V25.0785H52.243V48.811H44.4561V42.407H44.2526C43.0566 47.9571 40.4101 49.3886 36.7966 49.3886C31.7326 49.3635 29.2388 46.7266 29.2388 40.8248Z" fill="black"/>
                    <path d="M56.416 43.7637L60.8184 40.8254C62.2689 43.4874 64.5591 44.5171 67.5619 44.5171C69.8267 44.5171 70.8191 43.8642 70.8191 42.6085C70.8191 41.177 69.8521 40.4236 67.1547 39.9464L63.7957 39.3186C59.2661 38.5149 56.8232 35.9031 56.8232 32.2365C56.8232 27.3393 60.8184 24.5014 66.9257 24.5014C72.9821 24.5014 75.8322 26.9626 77.359 29.7251L73.0839 32.4374C71.9897 29.9762 69.7758 29.1726 67.74 29.1726C65.6279 29.1726 64.5082 29.926 64.5082 31.1566C64.5082 32.3369 65.4243 33.015 67.74 33.4419L71.1499 34.0698C76.1885 34.9739 78.6568 37.0081 78.6568 41.4281C78.6568 45.597 75.9594 49.3641 68.0199 49.3641C62.1671 49.3641 58.1973 47.1541 56.416 43.7637Z" fill="black"/>
                    <path d="M83.5175 42.4067V29.8247H80.2603V25.0781H83.5175V18.8499H91.686V25.0781H95.6303V29.8247H91.686V41.6784C91.686 43.2354 92.3222 43.8633 93.5182 43.8633H95.6303V48.8107H90.2864C85.1461 48.8107 83.5175 47.2034 83.5175 42.4067Z" fill="black"/>
                    <path d="M98.2002 25.0785H107.056L110.364 40.0212H110.567L114.792 25.0785H121.535L125.607 40.0212H125.81L129.475 25.0785H135.913L128.457 48.811H121.433L117.158 33.8934H116.955L112.883 48.811H105.86L98.2002 25.0785Z" fill="black"/>
                    <path d="M136.677 36.9328C136.677 29.3484 141.69 24.5014 149.171 24.5014C156.678 24.5014 161.666 29.3484 161.666 36.9328C161.666 44.5171 156.653 49.3641 149.171 49.3641C141.664 49.3641 136.677 44.5423 136.677 36.9328ZM153.116 36.9328C153.116 30.805 151.767 29.1977 149.171 29.1977C146.576 29.1977 145.227 30.805 145.227 36.9328C145.227 43.0605 146.576 44.6678 149.171 44.6678C151.767 44.6678 153.116 43.0605 153.116 36.9328Z" fill="black"/>
                    <path d="M165.279 25.079H172.964V32.9648H173.167C174.313 26.4603 176.526 24.9283 180.089 24.9283H180.522V32.0356H176.577C174.211 32.0356 173.447 32.789 173.447 35.6017V48.7865H165.279V25.079Z" fill="black"/>
                    <path d="M194.136 39.3182L191.795 41.8798V48.8112H183.703V16.1884H191.795V35.2749L200.141 25.0787H207.419L199.48 33.6174L207.928 48.8112H199.276L194.136 39.3182Z" fill="black"/>
                    <path d="M208.463 43.7637L212.891 40.8254C214.341 43.4874 216.631 44.5171 219.634 44.5171C221.899 44.5171 222.891 43.8642 222.891 42.6085C222.891 41.177 221.924 40.4236 219.227 39.9464L215.868 39.3186C211.338 38.5149 208.895 35.9031 208.895 32.2365C208.895 27.3393 212.891 24.5014 218.998 24.5014C225.054 24.5014 227.904 26.9626 229.431 29.7251L225.156 32.4374C224.062 29.9762 221.848 29.1726 219.812 29.1726C217.7 29.1726 216.58 29.926 216.58 31.1566C216.58 32.3369 217.497 33.015 219.812 33.4419L223.222 34.0698C228.261 34.9739 230.729 37.0081 230.729 41.4281C230.729 45.597 228.032 49.3641 220.092 49.3641C214.239 49.3641 210.244 47.1541 208.463 43.7637Z" fill="black"/>
                    <line x1="255.635" y1="8.6489e-08" x2="255.635" y2="72" stroke="black" stroke-width="3.95728"/>
                    <path d="M326.943 53.5089H316.448V21.3196H326.943V53.5089Z" fill="black"/>
                    <path d="M304.575 21.3503L295.831 21.3468L299.575 29.2054C300.838 31.8588 304.027 32.9917 306.696 31.734C307.696 31.2632 308.482 30.5224 309.006 29.6417C309.583 28.7929 309.92 27.7707 309.92 26.6696C309.921 23.7329 307.528 21.3512 304.575 21.3503Z" fill="black"/>
                    <path d="M286.069 22.4105C285.541 21.7281 284.439 21.3103 283.158 21.3493H273.369C284.998 41.6875 290.993 52.0614 291.352 52.4721C291.879 53.1545 292.982 53.5723 294.264 53.5333H304.052C292.431 33.2049 286.437 22.8309 286.069 22.4105Z" fill="black"/>
                    <path d="M338.814 53.5345L347.558 53.5372L343.815 45.6795C342.55 43.0251 339.362 41.8931 336.693 43.1499C335.692 43.6217 334.907 44.3616 334.382 45.2431C333.806 46.091 333.469 47.1142 333.468 48.2152C333.467 51.151 335.861 53.5328 338.814 53.5345Z" fill="black"/>
                    <path d="M357.322 52.4721C357.849 53.1545 358.951 53.5723 360.232 53.5333H370.021C358.392 33.196 352.398 22.8212 352.037 22.4105C351.511 21.7281 350.408 21.3103 349.127 21.3493H339.338C350.96 41.6777 356.953 52.0526 357.322 52.4721Z" fill="black"/>
                </svg>
            </div>
            </a>
        </header>
    );
};

export default JustworksHeader;
