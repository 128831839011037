import { cn } from "../../utils";
import Banner from "../Banner/Banner";
import Footer from "../Footer/Footer";
import Header from "../Header/Header";
import JustworksHeader from "../Header/JustworksHeader";
import SEO from "../Helmet/SEO";
import * as styles from "./Layout.module.scss";
import LayoutI from "./Layout.type";

const Layout = ({
  title,
  button,
  header_menu,
  footer_menu,
  no_menu,
  footer,
  children,
  logo,
  background = false,
  page_background,
  url,
  dataSEO,
  setModal,
  countryPage,
  blog,
  white = false,
  menuCTALink,
  banner,
  titleImage,
  headerBackgroundImage,
  headerBackgroundColor,
  hideFooter,
  justworksHeader,
}: LayoutI) => {
  return (
    <div
      className={cn([
        styles.container,
        justworksHeader ? styles.justworks : "",
        page_background ? styles["background"] : "",
        countryPage ? styles.footer_padding : "",
        headerBackgroundColor ? styles.headerBackgroundColor : "",
      ])}
    >
      {dataSEO && (
        <SEO
          metaTitle={dataSEO.metaTitle + " | Via"}
          metaDescription={dataSEO.metaDescription}
          keywords={dataSEO.keywords}
          preventIndexing={dataSEO.preventIndexing}
          url={url}
          rating={true}
          faqs={dataSEO.faqs}
        />
      )}
      {banner && <Banner banner={banner} />}
      {justworksHeader ? <JustworksHeader/> : <Header
        noMenu={no_menu}
        title={title}
        menu={header_menu}
        button={button}
        logo={logo}
        background={background}
        setModal={setModal}
        countryPage={countryPage}
        blog={blog}
        white={white}
        menuCTALink={menuCTALink}
        banner={banner ? true : false}
        titleImage={titleImage}
        headerBackgroundImage={headerBackgroundImage}
        headerBackgroundColor={headerBackgroundColor}
      />}
      {children}
      {(footer && !hideFooter) && (
        <Footer
          menu={footer_menu}
          links={footer}
          logo={logo}
          setModal={setModal}
          menuCTALink={menuCTALink}
        />
      )}
    </div>
  );
};

export default Layout;
