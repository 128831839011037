import { GatsbyImage } from "gatsby-plugin-image";
import * as styles from "./Banner.module.scss";
import BannerI from "./Banner.type";
import {extractSVGUrl} from "../../utils/helpers";

const Banner = ({
  banner,
}: BannerI) => {
  
  return (
    <div className={styles.bannerContainer}>
      <div className={styles.bannerText}>
        {banner.text}
      </div>
      <div className={styles.bannerImgContainer}><a href={banner.slug}>{banner.icon.gatsbyImageData ? 
        <GatsbyImage image={banner.icon.gatsbyImageData} alt={banner.icon.title}></GatsbyImage> :
         <img src={extractSVGUrl(banner?.icon?.publicUrl)}></img>
         }</a>
         </div>
    </div>
  );
};

export default Banner;
