import React from "react";
import * as styles from "./Logo.module.scss";
import { cn } from "../../utils";
import { Link } from "gatsby";
import LogoImageI from "./LogoImageI.type";

const Logo = ({ logo, className, footer, white }: LogoImageI) => {
  return (
    <div
      className={cn([
        styles.element,
        className ? className : "",
        footer ? styles.footerLogo : "",
        white ? styles.white : "",
      ])}
    >
      <Link to={"/"}>
        <img className={styles.img} src={logo.url} alt={logo.title} />
      </Link>
    </div>
  );
};

export default Logo;
