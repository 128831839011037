import { GatsbyImage } from "gatsby-plugin-image";
import { cn } from "../../utils";
import Button from "../Button/Button";
import Logo from "../Logo/Logo";
import Menu from "../Menu/Menu";
import HeaderTitle from "../HeaderTitle/HeaderTitle";
import * as styles from "./Header.module.scss";
import HeaderI from "./Header.type";

const Header = ({
  noMenu,
  title,
  menu,
  button,
  logo,
  background,
  setModal,
  countryPage,
  blog,
  white = false,
  menuCTALink,
  banner,
  titleImage,
  headerBackgroundImage,
  headerBackgroundColor,
}: HeaderI) => {
  return (
    <header
      className={cn([
        banner ? styles.hasBanner : "",
        countryPage ? styles.headerCountryPage : "",
        white ? styles.backgroundWhite : "",
        blog ? styles.headerBlog : "",
        !countryPage && !blog ? styles.header : "",
        background ? (headerBackgroundImage ? styles.backgroundImage : styles.backgroundGrey) : styles.background,
        headerBackgroundImage ? styles.headerBackgroundColor : "",
      ])}
      style={headerBackgroundImage ? { backgroundImage: `url('${headerBackgroundImage?.url}')` } : {}}
    >
      <div className={styles.element}>
        <Logo logo={logo} white={headerBackgroundColor ? true : false} />
        {!noMenu ? (
          <Menu items={menu} setModal={setModal} menuCTALink={menuCTALink} color={headerBackgroundColor ? "white" : "dark"} white={headerBackgroundColor ? true : false} />
        ) : (
          <Button
            className={"landingPage"}
            color={"blue"}
            text={"Request a Demo"}
            size="medium"
            url={menuCTALink}
            contactButton={false}
          />
        )}
      </div>
      {!countryPage && !blog && title && (
        <HeaderTitle
          type={"header"}
          h1_title={title?.h1_title}
          title={title.title}
          secondTitle={title.second_title}
          secondLine={title.second_line}
          description={title.description}
          titleColor={title.title_color}
          secondTitleColor={title.second_title_color}
          classNameTitle={styles.title}
          classNameText={styles.description}
          title_image={title?.title_image}
        />
      )}
      <div className={styles.titleImageWrapper}>
      {titleImage && titleImage.gatsbyImageData ? (<div className={styles.titleImageContainer}>
        <GatsbyImage image={titleImage.gatsbyImageData} alt={titleImage?.title}></GatsbyImage></div>) : 
        ( <div className={styles.titleImageContainer}><img src={titleImage?.url} alt={titleImage?.title}></img> </div>)
      }
      </div>
      {button && !countryPage && !blog && (
        <Button
          className={styles.button}
          shape={"rounded"}
          text={button.button_text}
          size="large"
          url={button.url}
          contactButton={true}
        />
      )}
    </header>
  );
};

export default Header;
