import React from "react";
import Logo from "../../Logo/Logo";
import * as styles from "./FooterBottom.module.scss";
import { Link } from "gatsby";
import FooterBottomI from "./FooterBottom.type";

const FooterBottom = ({ logo, links }: FooterBottomI) => {
  return (
    <div className={styles.element}>
      <Logo logo={logo} className={styles.logo} />
      <div className={styles.links}>
        <div>
          {links.map((link, index) => (
            <Link
              key={index}
              to={link.url ? link.url : "#"}
              className={styles.link}
            >
              {link.label}
            </Link>
          ))}
        </div>
        <span className={styles.copyright}>
          © Via {new Date().getFullYear()}. All rights reserved.
        </span>
      </div>
    </div>
  );
};

export default FooterBottom;
